import * as act from './action'

interface StateInterface {
    projectData: Record<string, any>,
    selectedProject: string,
    selectedLeaf: string,
    adminInfo: any,
    login: boolean
}

const initialState: StateInterface = {
    projectData: {},
    selectedProject: "",
    selectedLeaf: "",
    adminInfo: {},
    login: false 
}

type actionType = {
    type: string,
    payload: any
}

export default function globalPersistReducer(state = initialState, action: actionType) {
    switch (action.type) {
        case act.CHANGE_SELECTEDPROJECT:
            return { ...state, selectedProject: action.payload }
        case act.CHANGE_SELECTEDLEAF:
            return { ...state, selectedLeaf: action.payload }
        case act.UPDATE_PROJECTDATA:
            return { ...state, projectData: action.payload }
        case act.USERINFO:
            return { ...state, adminInfo: action.payload }
        case act.LOGIN:
            return { ...state, login: action.payload }
        default:
            return state
    }
}