import { FC, useEffect, useState } from "react"
import ReactDatePicker from "react-datepicker";
import { ClickAwayListener } from "@mui/material";
import { CalenderIconSvg, ChevronUpIconSvg } from ".."
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.scss"

type TimeField = { hours: string; minutes: string; meridiem: "AM" | "PM" | "" }

interface DatePickerInterface {
    setDate: React.Dispatch<React.SetStateAction<string>>;
    className: string;
    placeholder: string;
}

export const DatePicker: FC<DatePickerInterface> = ({ setDate, className = '', placeholder }) => {
    const [selDate, setSelDate] = useState<Date>(new Date());
    const [isDate, setIsDate] = useState<boolean>(false);
    const setDateValue = (val: Date | null) => {
        if (val !== null) {
            setIsDate(true);
        }
        if (val == null) val = new Date();
        setSelDate(val);
        let time: string = "23:59:59";
        setDate(`${val.getFullYear()}/${("00" + (val.getMonth() + 1)).slice(-2)}/${("00" + val.getDate()).slice(-2)},${time}`);
    };

    useEffect(() => {
        // setDateValue(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ReactDatePicker dateFormat="dd/MM/yyyy" placeholderText="dd/MM/yyyy" onChange={(dt) => setDateValue(dt)} minDate={new Date()}
            showDisabledMonthNavigation showPopperArrow={false}
            customInput={
                <div className={`datetimepicker-input ${className}`}>
                    <span style={{ flexGrow: 1 }}>{((isDate === false)) ? `${placeholder}` : `${("00" + selDate.getDate()).slice(-2)}/${("00" + (selDate.getMonth() + 1)).slice(-2)}/${selDate.getFullYear()} `}</span>
                    <img className="dds-icon" src={CalenderIconSvg} alt="date" onClick={_e => { }} />
                </div >
            }
        />
    )
}

interface TimePickerInterface {
    setTime: React.Dispatch<React.SetStateAction<string>>;
}

export const TimePicker: FC<TimePickerInterface> = ({ setTime }) => {
    const [selTime, setSelTime] = useState<TimeField>({ hours: "", minutes: "", meridiem: "" });
    const [showPicker, setShowPicker] = useState<boolean>(false);

    const setTimeValue = (t: TimeField) => {
        setSelTime(t);
        setTime(`${("00" + ((+t.hours % 12) + (t.meridiem === "PM" ? 12 : 0))).slice(-2)}:${("00" + +t.minutes).slice(-2)}: 00`);
    };

    const tNow: () => TimeField = () => ({
        hours: ("00" + (new Date().getHours() % 12 || 12)).slice(-2),
        minutes: ("00" + new Date().getMinutes()).slice(-2),
        meridiem: new Date().getHours() < 12 ? "AM" : "PM"
    });

    useEffect(() => {
        setTimeValue(tNow());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (val: string, field: keyof TimeField) => {
        const pt = { ...selTime };
        if (field === "hours") {
            if (isNaN(+val)) val = val.replace(/[^0-9]/g, "");
            else if (+val > 12) val = "12";
            pt[field] = val;
        } else if (field === "minutes") {
            if (isNaN(+val)) val = val.replace(/[^0-9]/g, "");
            else if (+val > 59) val = "59";
            pt[field] = val;
        } else if (field === "meridiem") {
            if (pt.hours === "" && pt.minutes === "") pt[field] = "";
        }
        setTimeValue(pt);
    };

    const handleBlur = (field: "H" | "M" | "T") => {
        const pt = { ...selTime };
        if (field === "H") {
            if (pt.hours === "") return;
            if (pt.minutes === "") pt.minutes = showPicker ? tNow().minutes : "00";
            if (pt.meridiem === "") pt.meridiem = "AM";
            if (pt.hours === "00" || pt.hours === "0") pt.hours = "12";
            else pt.hours = ("00" + pt.hours).slice(-2);
        } else if (field === "M") {
            if (pt.minutes === "") return;
            if (pt.hours === "") pt.hours = showPicker ? tNow().hours : "01";
            if (pt.meridiem === "") pt.meridiem = "AM";
            pt.minutes = ("00" + pt.minutes).slice(-2);
        }
        setTimeValue(pt);
    };

    const handleKeyDown = (k: string, field: "H" | "M" | "T", picker: boolean = false) => {
        if (!["ArrowUp", "ArrowDown", "a", "A", "p", "P"].includes(k)) return;
        const pt = (picker && Object.values(selTime).every(v => v === "")) ? { ...tNow() } : { ...selTime };
        if (field === "H") {
            if (pt.minutes === "") pt.minutes = "00";
            if (pt.meridiem === "") pt.meridiem = "AM";
            if (k === "ArrowUp") {
                if (pt.hours === "") pt.hours = "1";
                else if (pt.hours === "11") {
                    pt.hours = "12";
                    pt.meridiem = pt.meridiem === "AM" ? "PM" : "AM";
                } else pt.hours = ((+pt.hours + 1) % 12).toString();
            } else if (k === "ArrowDown") {
                if (pt.hours === "" || pt.hours === "01") pt.hours = "12";
                else if (pt.hours === "12") {
                    pt.hours = "11";
                    pt.meridiem = pt.meridiem === "AM" ? "PM" : "AM";
                } else pt.hours = ((+pt.hours - 1) % 12).toString();
            } else return;
            pt.hours = ("00" + pt.hours).slice(-2);
        } else if (field === "M") {
            if (pt.meridiem === "") pt.meridiem = "AM";
            if (k === "ArrowUp") {
                if (pt.hours === "") pt.hours = "01";
                if (pt.minutes === "") pt.minutes = "1";
                else if (pt.minutes === "59") {
                    pt.minutes = "00";
                    if (pt.hours === "11") {
                        pt.hours = "12";
                        pt.meridiem = pt.meridiem === "AM" ? "PM" : "AM";
                    } else pt.hours = ((+pt.hours + 1) % 12).toString();
                    pt.hours = ("00" + pt.hours).slice(-2);
                } else pt.minutes = ((+pt.minutes + 1) % 60).toString();
            } else if (k === "ArrowDown") {
                if (pt.hours === "") pt.hours = "12";
                if (pt.minutes === "") pt.minutes = "59";
                else if (pt.minutes === "00") {
                    pt.minutes = "59";
                    if (pt.hours === "01") pt.hours = "12";
                    else if (pt.hours === "12") {
                        pt.hours = "11";
                        pt.meridiem = pt.meridiem === "AM" ? "PM" : "AM";
                    } else pt.hours = ((+pt.hours - 1) % 12).toString();
                    pt.hours = ("00" + pt.hours).slice(-2);
                } else pt.minutes = ((+pt.minutes - 1) % 60).toString();
            } else return;
            pt.minutes = ("00" + pt.minutes).slice(-2);
        } else if (field === "T") {
            if (pt.meridiem === "") {
                pt.hours = "01";
                pt.minutes = "00";
                pt.meridiem = "AM";
            } else if (k === "a" || k === "A") pt.meridiem = "AM";
            else if (k === "p" || k === "P") pt.meridiem = "PM";
            else pt.meridiem = pt.meridiem === "AM" ? "PM" : "AM";
        }
        setTimeValue(pt);
    };

    return (
        <>
            <div className="datetimepicker-input" onClick={_e => setShowPicker(true)}>
                <div style={{ flexGrow: 1 }} className="timepicker-input">
                    <input type="text" value={selTime.hours} className="input-time-field" maxLength={2} placeholder="--" tabIndex={0}
                        onChange={e => handleChange(e.target.value, "hours")} onBlur={_e => handleBlur("H")} onKeyDown={e => handleKeyDown(e.key, "H")} />
                    <span>:</span>
                    <input type="text" value={selTime.minutes} className="input-time-field" maxLength={2} placeholder="--" tabIndex={1}
                        onChange={e => handleChange(e.target.value, "minutes")} onBlur={_e => handleBlur("M")} onKeyDown={e => handleKeyDown(e.key, "M")} />
                    <span> </span>
                    <input type="text" value={selTime.meridiem} className="input-time-field" maxLength={2} placeholder="--" tabIndex={2}
                        onChange={e => handleChange(e.target.value, "meridiem")} onBlur={_e => handleBlur("T")} onKeyDown={e => handleKeyDown(e.key, "T")} />
                </div>
                <svg className="dds-icon" onClick={_e => { }} width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 6C10 5.44792 9.89583 4.92708 9.6875 4.4375C9.47917 3.95833 9.19271 3.53646 8.82812 3.17188C8.46354 2.80729 8.04167 2.52083 7.5625 2.3125C7.07292 2.10417 6.55208 2 6 2C5.44792 2 4.92708 2.10417 4.4375 2.3125C3.95833 2.52083 3.53646 2.80729 3.17188 3.17188C2.80729 3.53646 2.52083 3.95833 2.3125 4.4375C2.10417 4.92708 2 5.44792 2 6C2 6.55208 2.10417 7.07292 2.3125 7.5625C2.52083 8.04167 2.80729 8.46354 3.17188 8.82812C3.53646 9.19271 3.95833 9.47917 4.4375 9.6875C4.92708 9.89583 5.44792 10 6 10C6.55208 10 7.07292 9.89583 7.5625 9.6875C8.04167 9.47917 8.46354 9.19271 8.82812 8.82812C9.19271 8.46354 9.47917 8.04167 9.6875 7.5625C9.89583 7.07292 10 6.55208 10 6ZM12 6C12 6.83333 11.8438 7.60938 11.5312 8.32812C11.2188 9.05729 10.7865 9.69792 10.2344 10.25C9.69271 10.7917 9.05729 11.2188 8.32812 11.5312C7.60938 11.8438 6.83333 12 6 12C5.16667 12 4.39062 11.8438 3.67188 11.5312C2.94271 11.2188 2.30208 10.7917 1.75 10.25C1.20833 9.69792 0.78125 9.05729 0.46875 8.32812C0.15625 7.60938 0 6.83333 0 6C0 5.16667 0.15625 4.39062 0.46875 3.67188C0.78125 2.94271 1.20833 2.30729 1.75 1.76562C2.30208 1.21354 2.94271 0.78125 3.67188 0.46875C4.39062 0.15625 5.16667 0 6 0C6.83333 0 7.60938 0.15625 8.32812 0.46875C9.05729 0.78125 9.69271 1.21354 10.2344 1.76562C10.7865 2.30729 11.2188 2.94271 11.5312 3.67188C11.8438 4.39062 12 5.16667 12 6ZM5 3V6.42188L7.29688 8.70312L8.70312 7.29688L7 5.57812V3H5Z" fill="black" />
                </svg>
            </div>
            {showPicker ? (
                <ClickAwayListener onClickAway={() => setShowPicker(false)}>
                    <div>
                        <div className="timepickerbox">
                            <div className="timepicker-item">
                                <img className="dds-icon" src={ChevronUpIconSvg} alt="up" onClick={_e => handleKeyDown("ArrowUp", "H", true)} />
                                <input type="text" value={selTime.hours} className="input-time-field" maxLength={2} placeholder={tNow().hours} tabIndex={3}
                                    onChange={e => handleChange(e.target.value, "hours")} onBlur={_e => handleBlur("H")} onKeyDown={e => handleKeyDown(e.key, "H", true)} />
                                <img className="dds-icon rotate180" src={ChevronUpIconSvg} alt="down" onClick={_e => handleKeyDown("ArrowDown", "H", true)} />
                            </div>
                            <div className="timepicker-item">
                                <img className="dds-icon" src={ChevronUpIconSvg} alt="up" onClick={_e => handleKeyDown("ArrowUp", "M", true)} />
                                <input type="text" value={selTime.minutes} className="input-time-field" maxLength={2} placeholder={tNow().minutes} tabIndex={4}
                                    onChange={e => handleChange(e.target.value, "minutes")} onBlur={_e => handleBlur("M")} onKeyDown={e => handleKeyDown(e.key, "M", true)} />
                                <img className="dds-icon rotate180" src={ChevronUpIconSvg} alt="down" onClick={_e => handleKeyDown("ArrowDown", "M", true)} />
                            </div>
                            <div className="timepicker-item">
                                <img className="dds-icon" src={ChevronUpIconSvg} alt="up" onClick={_e => handleKeyDown("ArrowUp", "T", true)} />
                                <input type="text" value={selTime.meridiem} className="input-time-field" maxLength={2} placeholder={tNow().meridiem} tabIndex={5}
                                    onChange={e => handleChange(e.target.value, "meridiem")} onBlur={_e => handleBlur("T")} onKeyDown={e => handleKeyDown(e.key, "T", true)} />
                                <img className="dds-icon rotate180" src={ChevronUpIconSvg} alt="down" onClick={_e => handleKeyDown("ArrowDown", "T", true)} />
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            ) : null}
        </>
    )
}