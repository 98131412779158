import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { api } from "../../api";
import { EditStepSvg, Grid, GridItem, SearchBox, TagGreyIconSvg, TrashIconSvg } from "../../global";
import { PopUpModel } from "../../global/components/PopUpModel";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT,SELECTEDLEAF } from "../store/action";
import CONST from "../../locale.json";
import { CreateLabel } from "./CreateLabel";
import "./EditLabel.scss";

const restrictedNames = ["high risk", "medium risk", "low risk"];
type TagInterface = { name: string; color: string; background: string; createdOn?: Date; modifiedOn?: Date; count?: Record<string, number> }

export const EditLabel = () => {
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [tagList, setTagList] = useState<TagInterface[]>([]);
    const [filterText, setFilterText] = useState<string>("");
    const [selectedTag, setSelectedTag] = useState<TagInterface>();
    const [deleteObj, setDeleteObj] = useState<{ show: boolean, tag: string }>({ tag: '', show: false });

    useEffect(() => {
        dispatch({type:SELECTEDPARENT, payload : 'Label Management'})
        dispatch({ type: SELECTEDLEAF, payload: 'EDITLABEL' })
    }, [])

    const getTagList = useCallback(async () => {
        const resp = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.FETCHLABEL, { query: { verbose: "true" } }, dispatch);
        setLoaded(true);
        if (resp.err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.msg } });
            return setTagList([]);
        }
        setTagList(resp.data);
    }, [dispatch]);

    useEffect(() => { getTagList() }, [getTagList]);

    const onDelete = async (tagName: string) => {
        const resp = await api(CONST.CHANNEL.TCM, CONST.METHOD.TCM.DELETELABEL, { params: tagName }, dispatch, "Processing");
        if (resp.err) dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.err } });
        else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Label deleted successfully" } })
            setDeleteObj({ tag: '', show: false });
        }
        getTagList();
    }

    return selectedTag ? <CreateLabel edit={selectedTag} editDone={() => { setSelectedTag(undefined); getTagList() }}></CreateLabel> :
        <div className="--edit-label-container">
            <div className="main-card dds-link">
                <div className="--label-header">
                    <span className="dds-h5-lm heading">Edit label</span>
                    <SearchBox onChange={setFilterText} placeholder="Search"></SearchBox>
                </div>
                {loaded && tagList.length === 0 ?
                    <div className="--label-body --empty">
                        <img className="--tag-icon" alt="tag-icon" src={TagGreyIconSvg}></img>
                        <span className="--label-item dds-link-sm">No labels created yet</span>
                    </div> :
                    <div className="--label-body">
                        <Grid className="--ltable-header dds-label-semibold">
                            <GridItem xs className="--ltable-sno">S.No.</GridItem>
                            <GridItem xs={6}>Label</GridItem>
                            <GridItem xs className="--ltable-col">Color</GridItem>
                            <GridItem xs className="--ltable-col">Used in</GridItem>
                            <GridItem xs className="--ltable-edit">Edit</GridItem>
                            <GridItem xs className="--ltable-del">Delete</GridItem>
                        </Grid>
                        <div className="--ltable-body">
                            {tagList.filter(t => t.name.toLowerCase().includes(filterText.toLowerCase())).map((t, i) => {
                                const readOnly = restrictedNames.includes(t.name.toLowerCase());
                                return (
                                    <Grid key={i} className="--ltable-item dds-body">
                                        <GridItem xs className="--ltable-sno">{i + 1}</GridItem>
                                        <GridItem xs={6}>{t.name}</GridItem>
                                        <GridItem xs className="--ltable-col"><div className="--color-dot" style={{ backgroundColor: t.background }}></div></GridItem>
                                        <GridItem xs className="--ltable-col">{Object.values(t.count || {}).reduce((sum, num) => { return sum + num })}</GridItem>
                                        <GridItem xs className={`--ltable-edit ${readOnly ? "disabled" : ""}`} onClick={() => { !readOnly && setSelectedTag(t) }}><img alt="Edit" src={EditStepSvg}></img></GridItem>
                                        <GridItem xs className={`--ltable-del ${readOnly ? "disabled" : ""}`} onClick={() => { !readOnly && setDeleteObj({ tag: t.name, show: true }) }}><img alt="Delete" src={TrashIconSvg}></img></GridItem>
                                    </Grid>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            {deleteObj.show && <PopUpModel Title="Delete" onClickClose={() => setDeleteObj({ tag: '', show: false })} onSaveClick={() => onDelete(deleteObj.tag)} screenBlock closeTitle="Cancel" saveTitle="Delete">
                <div className="body-content dds-body">Are you sure you want to delete this label?</div>
            </PopUpModel>}
        </div>
};