import { useNavigate } from "react-router";
import { UrlNotFoundSvg, Button } from "../../global";
import { useSelector } from "react-redux";
import { RootState } from "../../reducer";
import "./UrlNotFound.scss";

export const URLNotFound = (props: any) => {
    const navigate = useNavigate()
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);

    return (
        <>
            <div className="not-found">
                <img src={UrlNotFoundSvg} alt="404" />
                <p>Oops! Looks like something went wrong.</p>
                <Button title="Go to home page" onClick={() => navigate(devMode ? '/' : '/admin/')} className="reload"></Button>
            </div>
        </>
    )

}