import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";
import { Button, Dropdown, PassInfoIconSvg, CloseWhiteIcoSvg, AdminWebIconWhite, SearchDropdown, SearchboxSvg, SearchBox,InfoIconErrorSvg } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import CONST from "../../locale.json";
import { api } from "../../api";
import { RootState } from "../../reducer";
import { ToolTip } from "./ToolTip";
import { SELECTEDLEAF, SELECTEDPARENT } from "../store/action";
import "./CreateUser.scss";
import { SSOConfig } from "./SSOConfig";
import { filter } from "bluebird";

const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}

const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 400,
};

const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};

export const CreateUser = () => {

    const [craeteUser, setCreateUser] = useState<"NAME" | "PASSWORD">("NAME");
    const [fName, setFName] = useState<string>("");
    const [lName, setLName] = useState<string>("");
    const [userName, setUserName] = useState<string>("");
    const [emailID, setEmailID] = useState<string>("");
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [userType, setUserType] = useState<any>({});
    const [roleType, setRoleType] = useState<any>({});
    const [toolTip, showToolTip] = useState<boolean>(false);
    const [passwordCheck, setPasswordCheck] = useState(true);
    const [showOption, setShowOption] = useState(true);
    const [list, setList] = useState<any>([]);
    const [appType, setAppType] = useState<string>("");
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // all projects list
    const [projectList, setProjectList] = useState<any>([])
    const [showProjectList, setShowProjectList] = useState<any>([])
    // role id, label, value to show in dropdown,
    const [roleDictionary, setRoleDictionary] = useState<any>([])
    // user details 
    const [user, setUser] = useState<any>({})
    // user projects IDs json object to pass in api 
    const [userProject, setUserProject] = useState<any>([])
    const [filterText, setFilterText] = useState<string>("");
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    // To check if the project exists
    const [projectExists, setProjectExists] = useState<boolean>(false)
    const[errorDisplay,setErrorDisplay] = useState<string>("")
   
    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'User and Role Management' })
        dispatch({ type: SELECTEDLEAF, payload: "CREATEUSER" })
        //check if SSO is configured to allow user creation
        isSSOConfigured();
    }, [])

    const isSSOConfigured = async () => {
        try {
            const res = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHSSOCONFIG, {}, dispatch)
            if (res.data.length === 0) {
                setIsDisabled(true);
                dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "SSO is not configured" } })
            } else if (res.err) return res.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (res.err || res.msg) } }) : null;
        } catch (error) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: error } });
        }
    }

    const createUser = async () => {
        let valid = validate();
        if (valid === "valid") {
            // oidc and saml apis
            let user = {
                userName: userName,
                firstName: fName,
                lastName: lName,
                emailID: userName,
                type: "SSO creation",
                roleID: roleType.id,
                roleName: roleType.value
            }
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATEUSER, { body: user }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

            var selectedProjectsList: any = []
            list.forEach((element: any) => {
                selectedProjectsList.push(element.id)
            });
            // json object to pass in api
            let userProjects = {
                userID: resp.data.id,
                projectIDs: selectedProjectsList
            }
            if (userProjects.projectIDs.length !== 0) {
                const userProjectResp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.ASSIGNPROJECT, { params: "user", body: userProjects }, dispatch)
                if (userProjectResp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (userProjectResp.err || userProjectResp.msg) } });
            }
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Profile has been successfully created" } });
            navigate(devMode ? "/edituser" : "/admin/edituser")
        } 
        else if(valid !== undefined){
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: valid } });
        }
        
    }

    const validate = () => {
        let email: string = '';
        const NAME_REGEX = /^(?=.*[a-zA-Z0-9].*[a-zA-Z0-9])(?!.*_*__)[\w]+$/;
        if (emailID) {
            email = emailID
        }
        else if (Object.keys(roleType).length === 0) {
            setErrorDisplay("Please select the role")
            // return "Please select the role";
        }
        else if (userName === "" || userName.replace(/[^a-z]/gi, "").length < 2) {
            if (userName === "") {
                setErrorDisplay("Please enter the username")
                // return "Please enter the user name"
            }
            setErrorDisplay("Please enter atleast 2 alphabets")
            // return "Please enter atleast 2 alphabets";

        }
        else if(!(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(userName))){
            setErrorDisplay("Please enter a valid email id")

        }
        else if (fName === "") {
            setErrorDisplay("Please enter the first name")
            // return "Please enter the first name";
        }

        else if (!NAME_REGEX.test(fName)) {

            setErrorDisplay("First name is invalid")
            // return "First name is invalid"
        }

        else if (list.length === 0 && filterText !== "") {
            setErrorDisplay("Project needs to be selected from project list")
            return "Project needs to be selected from project list"

        }
        else if (!projectExists) {
            return "Project does not exist"
        }
        else {
            setErrorDisplay("")
            return "valid";
        }

    }

    const fetchRoles = async () => {
        // fetch all roles
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        // store them as id, label, value format to show in dropdown
        var roleDictionary: any = []
        for (var itr of resp.data) {
            roleDictionary.push({ id: itr.id, value: itr.name, label: itr.name })
        }
        setRoleDictionary(roleDictionary)
    }

    const fetchProjects = async (params: string, disabled: boolean = false) => {
        if (disabled) return
        // if fetch all projects
        setAppType(params);
        if (params !== "all") {
            params = `all/${params}`
        }
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHPROJECT, { params: params })
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        // set all projects/ web / sap
        setProjectList(resp.data.data)

        // get id, label, value for showing of project
        var prjList: any = []
        for (var itr of resp.data.data) {
            prjList.push({ id: itr.id, label: itr.name, value: itr.name })
        }

        // update state variable
        setProjectList(prjList);
    }
    const setName = useCallback((i: EventTarget & HTMLInputElement, name: string) => {
        switch (name) {
            case 'firstName':
                setFName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
                break;
            case 'lastName':
                setLName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
                break;
            case 'userName':
                setUserName(i.value.replace(/[^a-zA-Z0-9_@.]/g, ""));
                break;
            case 'email':
                setEmailID(i.value.replace(/[^a-zA-Z0-9_@.]/g, ""));
                break;
        }
    }, []);

    const passwordValidation = (e: any) => {
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$");
        setPasswordCheck(regex.test(e.target.value));
        setPassword(e.currentTarget.value);
    }
    const addToList = (e: any) => {
        let duplicate = false;
        setShowOption(true);
        list.forEach((ele: any) => {
            if (ele.id === e.id) {
                duplicate = true;
            }
        })
        if (duplicate) return;
        // add the selected project to assigned list
        setList((prev: any) => [...prev, { id: e.id, label: e.value.toString(), value: e.value.toString() }])
    }

    const removeFromList = (e: any) => {
        // remove the project from assigned list
        setList((state: any) => state.filter((item: any) => item !== e))
    }

    const fetchAzureAdUsers = async (e: any) => {
        let valid = validate();
        if (valid === "valid") {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `azure/users/${userName}` }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

            setFName(resp.data.firstName)
            setLName(resp.data.lastName)
            setEmailID(resp.data.emailID)
        } else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: valid } });
        }
    }

    useEffect(() => {


        const filteredList = projectList.filter((word: any) =>
            (filterText.length > 0) ?
                (word.label.toLowerCase()).includes((filterText.toLowerCase())) : projectList
        );

        setShowProjectList(filteredList)
        if (filteredList.length === 0) {
            setProjectExists(false)
        }
        else {
            setProjectExists(true)
        }

    }, [filterText, projectList])



    useEffect(() => {
        // fetch all roles to show in dropdown
        fetchRoles();
        // fetch all projects to show in checkbox list
        fetchProjects('all');
        setUserType(userType)
    }, [])

    return (
        <div className="create-user-container dds-link">
            {(() => {
                return (<>
                    <h3>Create user profile</h3>
                    <div className="create-user-div">
                        <div className="row">
                        <label>Select role</label>
                        <div className="create-user-txtbox ">
                            <Dropdown className={(errorDisplay==="Please select the role")?"error-dropdown":"" }placeholderStyle={placeholderStyle} style={{ width: '470px',height:"40px"}} controlStyle={DropdownStyle} dropdownIndicatorStyle={DropdownIndicatorStyle}
                                showValue={true} defaultValue={roleType} onchange={(e: any) => setRoleType({ id: e.id, label: e.value, value: e.value })} options={roleDictionary} placeholder="Select" disabled={isDisabled} />
                                {errorDisplay==="Please select the role" && 
                    <img className="info-icon"src={InfoIconErrorSvg}></img>}
                        </div>
                        </div>
                        {errorDisplay==="Please select the role" && 
                    <div className="error-message">{errorDisplay}</div>}   
                    </div>
                       
                    <div className="create-user-div">
                        
                        <div className="row">
                        <label>Username</label>
                        <div className="create-user-txtbox">
                            
                                <input className={(errorDisplay==="Please enter the username" || errorDisplay==="Please enter atleast 2 alphabets" || errorDisplay==="Please enter a valid email id")?"error-textbox":"admin-textbox"}  value={userName} onChange={(e) => setName(e.target, "userName")} type="text" maxLength={50} disabled={isDisabled} />
                                {(errorDisplay==="Please enter the username" || errorDisplay==="Please enter atleast 2 alphabets" || errorDisplay==="Please enter a valid email id") && 
                    <img className="info-icon"src={InfoIconErrorSvg}></img>}
                                </div>
                        </div>
                        {(errorDisplay==="Please enter the username" || errorDisplay==="Please enter atleast 2 alphabets" || errorDisplay==="Please enter a valid email id") && 
                    <div className="error-message">{errorDisplay}</div>}
                    
            
                    </div>
                    
                    
                    
                    

                    <div className="create-user-div">
                        <div className="row">
                        <label>First name</label>
                        <div className="create-user-txtbox ">
                            <input className={(errorDisplay==="First name is invalid"|| errorDisplay==="Please enter the first name")?"error-textbox":"admin-textbox"} value={fName} onChange={(e) => setName(e.target, "firstName")} type="text" disabled={isDisabled} />
                            {(errorDisplay==="First name is invalid"|| errorDisplay==="Please enter the first name") && 
                    <img className="info-icon"src={InfoIconErrorSvg}></img>
            }
                        </div>
                        </div>
                        {(errorDisplay==="First name is invalid"|| errorDisplay==="Please enter the first name") && 
                    <div className="error-message">{errorDisplay}</div>
            }
                    </div>
                    

                    <div className="create-user-div">
                        <div className="row">
                        <label>Last name</label>
                        <div className="create-user-txtbox">
                            <input className="admin-textbox" value={lName} onChange={(e) => setName(e.target, "lastName")} type="text" disabled={isDisabled} />
                        </div>
                        </div>
                    </div>

                    {
                        roleType.value !== "admin" ?
                            <>
                                <div className="create-user-div">
                                    <label>Assign project</label>
                                </div>
                                <div className="create-user-div">
                                <div className="row">
                                    <label style={{ color: CONST.COLOR.BORDERGREY }}>Select app type</label>
                                    <div className="select-prj-div">
                                        <div className="app-type">
                                            {CONST.appType.map((apptype) => (
                                                <div key={apptype.name} className={`app-type-item noselect ${apptype.disabled ? "disable-feature" : ""}`} onClick={() => { fetchProjects(apptype.name, apptype.disabled) }}>
                                                    <div className="radio-select">
                                                        <input type="radio" className="radio" name="app" disabled={apptype.disabled || isDisabled} checked={apptype.name == appType} />
                                                        <span>{apptype.name}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="select-project">
                                            <ClickAwayListener onClickAway={() => setShowOption(true)}>
                                                <div id="search-div" style={{ width: "470px" }} onClick={(e: any) => { if (e.id === 'search-div') { setShowOption(false) } }}>

                                                    <SearchDropdown className="--search-card" placeholder="Search project" value={filterText} onChange={setFilterText} options={showProjectList} onclick={addToList} disabled={isDisabled} />
                                                </div>
                                            </ClickAwayListener>
                                            {showOption && list.length > 0 ?
                                                <div className="assign-new-prj-list">{list.map((element: any) =>
                                                    <div className="Prj-Label dds-link-sm" key={element.value.toString()}>
                                                        <img src={AdminWebIconWhite}></img>
                                                        <span title={element.value.toString()}>{element.value.toString()}</span>
                                                        <img style={{ width: "10%" }} src={CloseWhiteIcoSvg} onClick={() => removeFromList(element)}>
                                                        </img>
                                                    </div>
                                                )}</div>
                                                : null}
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </> : null
                    }
                </>
                )
            }
            )()
            }
            <div className="create-user-btn">
                <Button title={"Submit"} className="--savebtn" onClick={createUser} disabled={isDisabled} />
            </div>
        </div >
    )
}
