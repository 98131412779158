import { useEffect } from "react";
import { Outlet, useMatch } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "./reducer";
import { TreeViewer, Header, Notify, BlockScreenMsg } from "./global";
import { AdminHome } from "./admin/components/AdminHome";
import { SETDEVMODE } from "./global/store/action";
import "./admin/Layout.scss"


export const Layout = () => {
    const env: boolean = (process.env.NODE_ENV || "").toLowerCase() === "development";
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SETDEVMODE, payload: env })
    }, [])
    const uName = useSelector((state: RootState) => state.adminReducer.userName);
    const match = useMatch(env ? '/' : '/admin/')
    return (
        <>
            <Header userName={uName.userName} />
            <Notify />
            {match ? <AdminHome /> :
                (<>
                    <div className="Admin-body">
                        <TreeViewer />
                        <div className="--content">
                            <div className="--mainbox">
                                <Outlet />
                            </div>
                            <BlockScreenMsg />
                        </div>
                    </div>

                </>)
            }
        </>
    )
}




