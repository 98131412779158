import { CSSProperties, FC, MouseEventHandler } from "react"
import './Button.scss'

interface ButtonInterface {
    title: string,
    onClick: MouseEventHandler<HTMLDivElement>,
    style?: CSSProperties,
    disabled?: boolean,
    id?: string
    className?: string;
}

export const Button: FC<ButtonInterface> = ({ style, title, onClick, disabled = false, className, id = "" }) => {
    const onClickBtn = (e: any) => {
        if (disabled) return;
        onClick(e)
    }
    return (
        <div id={id} className={"btn-main noselect dds-body-normal" + (disabled ? " disabled" : "") + (className ? ` ${className}` : "")} style={style} onClick={onClickBtn}>
            {title}
        </div>
    )
}