import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import CONST from "../../locale.json";
import { api } from "../../api"
import { TableView } from './TableView';
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDLEAF, SELECTEDPARENT } from "../store/action";

const header =
    [{
        "microService": "Microservices",
        "statusCode": "Status code",
        "statusText": "Description",
        "respTime": "Response time",
        "version": "Version"
    }];
export const MicroserviceHealth = () => {

    const [health, setHealth] = useState<any>([]);
    const [microserviceHealth, setMicroserviceHealth] = useState<any>([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'Status and session' })
        dispatch({ type: SELECTEDLEAF, payload: 'MICROSERVICE' })
    }, [])

    const getHealth = async () => {
        try {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.GETMICROSERVICEHEALTH, {}, dispatch);
            if (resp.err || resp.data.length == 0) {

                if(resp.err.includes("ECONNREFUSED")){
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Database is down" } });
                }
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "No health records found" } });
            }

            setMicroserviceHealth(resp.data);
            resp.data.statusCheck.forEach((item: any) => {
                for (let key in item) {
                    if (!(Object.keys(header[0]).includes(key))) {
                        delete item[key]
                    }
                    if(key === "microService"){
                        item[key]=camelCaseToWords(item[key])
                    }
                }
            })
            setHealth(resp?.data?.statusCheck)
        }
        catch (err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "No heath records found" } });
        }
    }
    useEffect(() => {
        getHealth()
    }, [])

    
    function camelCaseToWords(s: string) {
        const result = s.replace(/([A-Z])/g, ' $1').toLowerCase();
        return result.charAt(0).toUpperCase() + result.slice(1);
      };

    return (
        <>
            <TableView Title={"Microservices' health"} tableData={health} onClickRefresh={() => getHealth()} showSLNo={false} header={Object.values(header[0])} filter="microService" sessionTime={microserviceHealth.createdOn} searchPlaceHolder="Search microservices" />
        </>
    )

}