import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import { api } from "../../api";
import CONST from "../../locale.json";
import './SMTPConfig.scss';
const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}
const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 500,
};
const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
export const SMTPConfig = () => {
    const [id, setId] = useState("");
    const [email, setEmail] = useState("");
    const [shouldDisable, setShouldDisable] = useState<boolean>(false);
    const [dropDownVal, setDropDownVal] = useState<Record<"label" | "value", string>>({ label: "", value: "" })
    const [userList, setUserList] = useState<any>([]);
    const [isExists, setIsExists] = useState<boolean>(false);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: 'SMTPCONFIG' })
        dispatch({ type: SELECTEDPARENT, payload: 'SSO configuration' })
    }, [])

    const testConfig = async () => {
        let testConfigBody: Record<string, any>;
        testConfigBody = {
            from: dropDownVal.value,
            to: dropDownVal.value,
            subject: "Total Testware Test Email"
        }
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.TESTNOTIFICATIONCONFIG, { params: "/outlook/test-config", body: testConfigBody }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SMTP tested successfully" } });
    }

    const createConfig = async () => {
        let configBody = {
            from: dropDownVal.value,
        }
        // if config already exist then call update api
        if (isExists) {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATENOTIFICATIONCONFIG, { params: `/outlook/config/${id}`, body: configBody }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SMTP config updated successfully" } });
        }
        //if config not exist then call create api
        else {
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATENOTIFICATIONCONFIG, { params: `outlook/config`, body: configBody }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "SMTP config created successfully" } });
        }
        fetchConfig()
    }

    const fetchVerifiedUser = async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHVERIFIEDUSERS, { params: "/verifiedIdentities" }, dispatch)
        // if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        if (resp.data.data.length === 0) return;
        else {
            var users: any = [];
            for (var itr of resp.data.data) {
                users.push({ label: itr, value: itr })
            }
            setUserList(users);
        }
    }

    const fetchConfig = async () => {
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHNOTIFICATIONCONFIG, { params: "/outlook/config" }, dispatch)
        if (resp.err) {
            setShouldDisable(true)
            
                if(resp.err.includes("ECONNREFUSED")){
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Database is down" } });
                }
                return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            
        }
        if (resp.data.data.length === 0) return;
        else {
            setId(resp.data.data[0].id)
            setEmail(resp.data.data[0].from)
            setDropDownVal({ value: resp.data.data[0].from, label: resp.data.data[0].from })
            setIsExists(true)
        }
    }


    useEffect(() => {
        // it will fetch config if already exists
        fetchConfig();
        fetchVerifiedUser();
    }, [])

    return (
        <div className="SMTP-config-container dds-link ">
            <h3 className="dds-h5-lm">SMTP configuration</h3>
            <div className="SMTP-config-div">
                <label>Sender Email ID</label>
                <Dropdown placeholderStyle={placeholderStyle} controlStyle={DropdownStyle} dropdownIndicatorStyle={DropdownIndicatorStyle} style={{ width: "100%" }}
                    onchange={(e: any) => { setDropDownVal({ value: e.value, label: e.value }) }} defaultValue={dropDownVal} placeholder="Select" showValue options={userList} disabled={shouldDisable} />
            </div>
            <div className="SMTP-config-btn">
                <Button title={"Submit"} style={{ width: "18%" }} className="--resetbtn" onClick={createConfig} />
                <Button title={"Test"} style={{ width: "18%" }} className="--resetbtn" onClick={testConfig} />
            </div>
        </div>
    )
}