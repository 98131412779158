import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, DatePicker, EncryptIconSvg, ImportIconSvg } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT,SELECTEDLEAF } from "../store/action";
import { api } from "../../api";
import CONST from "../../locale.json";
import './CreateApiKey.scss';
import { Buffer } from 'buffer';

export const CreateApiKey = () => {
    const dispatch = useDispatch();

    const [isCopied, setIsCopied] = useState(false);
    const [name, setName] = useState("")
    const [date, setDate] = useState("")
    const [apiKey, setApiKey] = useState("");

    const regex = /^(?=.*[a-zA-Z0-9]).{2,}$/;

    useEffect(() => {
        dispatch({type:SELECTEDPARENT, payload : 'Third-party integration'})
        dispatch({ type: SELECTEDLEAF, payload: 'CREATEAPIKEY' })
    }, [])

    const createApiHandle = async () => {
        
        if (!regex.test(name)) {
            return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please ensure the name is at least 2 characters long and has at least one alphanumeric character." } });

        }
        let payload = {
            keyName: Buffer.from(name as string).toString('base64'),
            expiry: date,
        }
        const res = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATEAPIKEY, { body: payload }, dispatch)
        if (res.err) {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: res.msg || res.err } });
        }
        res.data.apiKey = Buffer.from(res.data.apiKey, 'base64').toString('utf-8')
        setApiKey(res.data.apiKey);
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "API key has been successfully created" } });
        dispatch({ type: CALL_NOTIFY, payload: { type: "INFO", msg: "The API key has been generated, please copy/download the key here. The key will not be displayed again." } })
    }

    const onExport = (key: string, value: string) => {
        const keyval = {
            keyName: key,
            apiKey: value
        };

        const blob = new Blob([JSON.stringify(keyval)], { type: "text/json;charset=utf-8;" });
        const href = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = href;
        link.download = "data.txt";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        URL.revokeObjectURL(href);
    };

    const handleCopy = (apiKey: string) => {
        if (apiKey) {
            navigator.clipboard.writeText(apiKey)
            setIsCopied(true)
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Text copied" } });
        }
    }
    

    //handlePaste function helps to remove special characters that are not allowed other than underscore when user paste something 
    const handlePaste = (e:React.ClipboardEvent<HTMLInputElement>)=>{
        e.preventDefault();
        const pastedText = e.clipboardData.getData('Text');
        const filteredText = pastedText.replace(/[^A-Za-z0-9_]/g,"");
        setName(name+filteredText)
    }

    useEffect(() => {
        setApiKey("")
        setIsCopied(false)
    }, [name, date])
    

    return (
        <div className="create-apiKey-container dds-link">
            <h3 >Create API key</h3>
            <div className="form-body">
                <div className="--fields">
                    <label>Key name</label>
                    <input className="--input" type="text" maxLength ={20} value={name} onChange={(e) => setName(e.target.value.replace(/[^A-Za-z0-9_]/, ""))} placeholder="" onPaste={handlePaste} />
                </div>
                <div className="--fields">
                    <label>Expiry</label>
                    <div className="--date-picker">
                        <DatePicker setDate={setDate} className={"--date-select"} placeholder={``} />
                    </div>
                </div>
                <div className="--fields">
                    <label>API key</label>
                    <div className="--api-key">
                        <p id="textToCopy">{apiKey}</p>
                        <span>
                            <img
                                id="--copy"
                                title={"Copy"}
                                className={`--copy ${!apiKey || isCopied ? 'disabled' : ''}`}
                                alt="import"
                                src={EncryptIconSvg}
                                onClick={() => handleCopy(apiKey)}
                            />
                            <img className={`--import ${!apiKey ? 'disabled' : ''}`} title={"Download"} alt="import" src={ImportIconSvg} onClick={() => onExport(name, apiKey)}></img>
                        </span>
                    </div>
                </div>
                <div className="--fields">
                    <div></div>
                    <Button title="Create" onClick={createApiHandle} disabled={!(name && date && !apiKey)} />
                </div>
            </div>
        </div >
    )
}
