import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LicenseBrandSvg, Button } from "../../global"
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG, USERINFO, LOGIN } from "../../global/store/action"
import { ACCESSTOKEN, ADMINROLEID, USERNAME } from "../store/action"
import { api } from "../../api"
import CONST from "../../locale.json"
import "./Login.scss";
import { RootState } from "../../reducer"

export const Login = () => {
    const usernameRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const confirmPasswordRef = useRef<HTMLInputElement>(null)

    const [password, setPassword] = useState<boolean>(false)
    const [isCognitoUserPoolInitialized, setIsCognitoUserPoolInitialized] = useState<boolean>(true);
    const [userName, setUserName] = useState<string>("");

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login: Boolean = useSelector((state: RootState) => state.globalPersistReducer.login);

    const validate = () => {
        let email: string = '';
        if (emailRef?.current?.value) {
            email = emailRef?.current?.value
        }
        else {
            email = "";
        }
        if (firstNameRef?.current?.value === "") {
            return "Please enter the first name";
        }
        if (emailRef?.current?.value === "") {
            return "Please enter the email"
        }

        if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))) {
            return "Please enter valid email id"
        }

        if (usernameRef?.current?.value === "") {
            return "Please enter the username";
        }
        if (passwordRef?.current?.value === "") {
            return "Please enter the password";
        }

        else {
            return "valid";
        }

    }

    const onNext = () => {
        document?.getElementById("password")?.focus();
    }

    const checkEnter = (e: React.KeyboardEvent<HTMLInputElement>, cb: CallableFunction) => {
        if (['Enter', 'NumpadEnter'].includes(e.key)) cb()
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const updateURL = () => {
            if (searchParams.has('code')) {
                const url = new URL(window.location.href);
                searchParams.delete('code');
                url.search = searchParams.toString();
                // Replace the current URL
                navigate(url.pathname + url.search, { replace: true })
            }
            ;
        };
        if (localStorage.getItem('accessToken')) {
            (async () => {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.VALIDATE, { params: "validate" }, dispatch);
                if (resp.err === "USERPOOLCLIENTDBINITERR") {
                    setIsCognitoUserPoolInitialized(false);
                }
                else {
                    setIsCognitoUserPoolInitialized(true);
                    if (resp.data?.userName) {
                        dispatch({ type: USERNAME, payload: { userName: resp.data.userName } });
                        dispatch({ type: ADMINROLEID, payload: resp.data.adminRoleID });
                        dispatch({ type: LOGIN, payload: true });
                    }
                }
            })();
        }
        else if (searchParams.get('code')) {
            (async () => {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.GETTOKEN, { params: "getaccesstoken/admin", body: { code: searchParams.get('code') } }, dispatch);
                if (resp.data?.errorCode === "USR_DOESNT_EXIST") {
                    dispatch({ type: LOGIN, payload: false });
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.data.errorMessage } });
                }
                if (resp.data?.errorCode === "SESSION_ALREADY_EXISTS") {
                    dispatch({ type: LOGIN, payload: false });
                    return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.data.errorMessage } });
                }
                if (resp.data?.accessToken) {
                    localStorage.setItem('accessToken', resp.data?.accessToken);
                    localStorage.setItem('refreshToken', resp.data?.refreshToken);
                    localStorage.setItem('userName', resp.data?.userName);
                    localStorage.setItem('isloggedin',"true");
                    dispatch({ type: USERNAME, payload: { userName: resp.data.userName } });
                    dispatch({ type: USERINFO, payload: { userInfo: resp.data } });
                    dispatch({ type: ADMINROLEID, payload: resp.data.adminRoleID });
                    dispatch({ type: LOGIN, payload: true });
                    return;
                }
            })();
        }
        // get started button case
        else {
            (async () => {
                const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.VALIDATE, { params: "validate" }, dispatch);
                if (resp.err === "USERPOOLCLIENTDBINITERR") {
                    setIsCognitoUserPoolInitialized(false);
                }
                else {
                    setIsCognitoUserPoolInitialized(true);
                    if (resp.data?.userName) {
                        dispatch({ type: USERNAME, payload: { userName: resp.data.userName } });
                        dispatch({ type: LOGIN, payload: true });
                    }
                }
            })();
        }
        updateURL()
    }, [dispatch, login])

    useEffect(()=>{
        if(localStorage.getItem("isloggedout")){
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Successfully logged out" } });
            localStorage.removeItem("isloggedout")
        }
    })


    const authenticateSSO = async () => {
        const resp: any = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.SSOLOGIN, { params: 'adminSignInUrl' }, dispatch);
        if (resp.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: resp.err } });
        window.location.href = resp.data.hostedUI;
    }

    const initCognito = async () => {
        // create realm, client, admin user
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.INITUSERPOOL, {}, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        // set initialized true
        setIsCognitoUserPoolInitialized(true)
    }



    return (
        <div className="root-main">
            <div className="login-home-main">
                <div className="--body">
                    <div className="--log-container">
                        <img className="--log" alt="product-logo" src={LicenseBrandSvg} />
                    </div>
                    {
                        isCognitoUserPoolInitialized ?
                            <div className="--content">
                                <div className="--bodyheader">
                                    <div className="--title-head dds-h2">Welcome!</div>
                                    <div className="--title-head dds-h3-sm">Login to get started</div>
                                </div>
                                <div className="--footercontainer">
                                    <Button title="Login with SSO" onClick={authenticateSSO} className="start-btn" />
                                </div>
                            </div>
                            :
                            <div className="--content" style={{ justifyContent: 'center' }}>
                                <Button title="Get Started" onClick={() => initCognito()} className="start-btn" />
                            </div>
                    }
                </div>


            </div >
            <div className="--footer">
                Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited, a UK private company limited by guarantee ("DTTL"), its network of member firms, and their related entities. DTTL and each of its member firms are legally separate and independent entities. DTTL (also referred to as "Deloitte Global") does not provide services to users.
                This tool is a AI based testing tool (Total Testware™) for external use by Deloitte members developed and exclusively owned by Deloitte Shared Services India LLP (DSSILLP). The tool can be used to perform an overall test diagnosis of an application. The user shall not copy, reproduce, modify, distribute, or disseminate the tool, nor will the user reverse engineer, decompile, dismantle, or obtain access to the underlying formulae of the tool.
                © 2024 Deloitte Touche Tohmatsu India LLP.
            </div>
        </div>
    )
}

