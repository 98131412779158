import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import globalReducer from "./global/store/reducer";
import globalPersistReducer from "./global/store/persistReducer";
import adminReducer from "./admin/store/reducer";
//white list the reducers to retain value on refresh
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['globalPersistReducer']
}

const rootReducer = combineReducers({
  globalPersistReducer,
  globalReducer,
  adminReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
