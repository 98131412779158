
import { useDispatch, useSelector } from "react-redux";
import { useRef, useCallback, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CONST from "../../locale.json"
import { api } from "../../api"
import { CALL_NOTIFY } from "../../global/store/action";
import { RootState } from "../../reducer";
import { Button, AdminAPIIcon, AdminDesktopIcon, AdminSAPIcon, AdminWebIcon, AdminDatabaseIcon } from "../../global";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import "./CreateProject.scss";

interface appTypeInterface {
    name: string;
    icon: string;
    disabled: boolean;
}

export const CreateProject = () => {

    const projectnameRef = useRef<HTMLInputElement>(null);
    const [selectedApp, setSelectedApp] = useState("");
    const [projectName, setProjectName] = useState<string>("")
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [createButtonDisabled, setCreateButtonDisabled] = useState<boolean>(true);
    const NAME_REGEX =/^(?=.*[a-zA-Z0-9].*[a-zA-Z0-9])(?!.*_*__)[\w]+$/;

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'Project management' })
        dispatch({ type: SELECTEDLEAF, payload: 'CREATEPROJECT' })
    }, [])

    useEffect(() => {
        // Enable the button when both project name and app type are selected
        if (projectName && selectedApp) {
            setCreateButtonDisabled(false);
        } else {
            setCreateButtonDisabled(true);
        }
    }, [projectName, selectedApp]);

    const CreateNewProject = async () => {
        // create project 
        if (projectnameRef.current) {
            if (projectnameRef.current.value === "") {
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please enter the project name" } });
            }
            else if(!NAME_REGEX.test(projectnameRef.current.value)){
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Project name is invalid" } });
            }
            else if ((projectnameRef.current.value.length) < 2) {
                return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Project name must have minimum 2 characters" } });
            }
        }

        if (selectedApp === "") {
            return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Please select app type" } });
        }

        let project = {
            name: projectnameRef.current?.value,
            appType: selectedApp
        }
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.CREATEPROJECT, { body: project }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        navigate(devMode ? "/projectlist" : "/admin/projectlist")
        dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully created" } });
        setCreateButtonDisabled(true);

    }
    const setName = useCallback((i: EventTarget & HTMLInputElement) => {
        setProjectName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
    }, []);

    const getTargetIcon = useCallback((app: string) => ({
        web: AdminWebIcon,
        sap: AdminSAPIcon,
        api: AdminAPIIcon,
        database: AdminDatabaseIcon,
        desktop: AdminDesktopIcon
    }[app]), []);

    const selectedAppHandler = (selectedApp: appTypeInterface) => {
        if (selectedApp.disabled) return
        setSelectedApp(selectedApp.name)
    }

    return (
        <div className=" create-project-container dds-body ">
            <h3 className="dds-h5-lm">Create project</h3>
            <div className="create-Project-div">
                <label>Project name</label>
                <div className="apptype-select">
                    <input ref={projectnameRef} value={projectName} className={"admin-textbox"} onChange={(e: any) => { setName(e.target) }} type="text" maxLength={50} />
                </div>
            </div>
            <div className="select-app-div">
                <label>App type</label>
            </div>
            <div className="select-app">
                {CONST.appType.map((e) => (
                    <div key={e.name} className={`${(e.name === selectedApp) ? "selectedApp apptype-select" : "apptype-select"} ${e.disabled ? "disable-feature" : ""}`} onClick={() => selectedAppHandler(e)} >
                        <img src={getTargetIcon(e.icon)}></img>
                        <label>{e.name}</label>
                    </div>))}
            </div >
            <div className="create-project-btn">
                <Button title="Create project" className="--savebtn" onClick={CreateNewProject} disabled={createButtonDisabled} />
            </div>
        </div >
    )
}