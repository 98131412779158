import React, { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/material";
import { CALL_NOTIFY, LOGIN } from "../store/action";
import { api } from "../../api";
import { HeaderInfoIcon, HeaderUserIcon, HeaderUserIconActive, DeloitteLogoSvg, SearchDropdown } from "../";
import CONST from "../../locale.json";
import { SELECTEDADMINSCREEN, SELECTEDPARENT } from "../../admin/store/action";
import { RootState } from "../../reducer";
import "./Header.scss";

/**
 * Main header component
 *
 * @returns the main header component for the page
 */

type OptionsType = { value: string, label: string };
interface HeaderInterface {
    userName?: string,
}
const menu: any = CONST.menu;

export const Header: FC<HeaderInterface> = ({ userName }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [options, setOptions] = useState<any>(menu);
    const [showUser, setShowUser] = useState<boolean>(false);
    const [filterText, setFilterText] = useState<string>("");
    const [userNameAdmin, setUserNameAdmin] = useState<string>("");
    const [userAcronym, setUserAcronym] = useState<string>("");
    const [userLastAcronym, setLastUserAcronym] = useState<string>("");
    const [userFirstName, setUserFirstName] = useState<string>("")
    const [userLastName, setUserLastName] = useState<string>("")
    const [version,setVersion] = useState<string>("")

    const login = useSelector((state: RootState) => state.globalPersistReducer.login);
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const adminInfo = useSelector((state: RootState) => state.globalPersistReducer.adminInfo)

    //TODO
    const onClickInfo = () => {
    }

    const LogOut = async () => {
        var res = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.LOGOUT, { params: `/logout/admin/${userName}` }, dispatch);
        if (res.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: res.msg } });
        setShowUser(false);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        dispatch({ type: LOGIN, payload: false });
        localStorage.setItem("isloggedout","true");
        navigate(devMode ? "/" : "/admin/")
    }

    const SelectedOption = (e: any) => {
        //Blocked for license management
        if (e.value === "CREATENEWLICENSE" || e.value === "LISTOFLICENSE") return
        dispatch({ type: SELECTEDADMINSCREEN, payload: e.value });
        CONST.tree.forEach((parent: any) => {
            parent.children.forEach((element: any) => {
                if (element.id === e.value) {
                    // dispatch({ type: SELECTEDPARENT, payload: parent.id });
                    const page = element.id.toLowerCase()
                    navigate(devMode ? "/" + page : "/admin/" + page)
                }
            })
        })

    }
    useEffect(() => {
        setUserNameAdmin(localStorage.getItem("userName") as any);
        setOptions(menu.filter((word: any) =>
            word.label.toLowerCase().includes(filterText.toLowerCase())
        )
        );
    }, [filterText])

    useEffect(() => {

        (async () => {
            let userDetails = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `/admin/${userNameAdmin}` }, dispatch);
            setUserFirstName(userDetails?.data?.firstName)
            setUserLastName(userDetails?.data?.lastName || "")
            const fNameInitial = userDetails?.data?.firstName[0]?.toUpperCase() || '';
            const lNameInitial = userDetails?.data?.lastName && userDetails?.data?.lastName[0].toUpperCase() || userDetails?.data?.firstName[1]?.toUpperCase();
            setUserAcronym(fNameInitial);
            setLastUserAcronym(lNameInitial)
            setVersion(userDetails?.data?.version)
        })();
    },);


    return (
        <div className={"main-header noselect" + (login ? "" : " no-underline")}>
            <img className="--logo" src={DeloitteLogoSvg} onClick={() => navigate(devMode ? "/" : "/admin/")} alt="logo" />
            {
                login ?
                    <>
                        <div className="--image-box">
                            <img src={HeaderInfoIcon} className="impicon" onClick={onClickInfo} alt={"Header Info"} />
                            <img
                                id="HeaderUserIcon"
                                src={showUser ? HeaderUserIconActive : HeaderUserIcon}
                                className={showUser ? "--active" : ""}
                                alt={"Header User"}
                                onClick={() => {
                                    setShowUser(!showUser);
                                }}
                            />
                        </div>

                        {showUser && (
                            <ClickAwayListener onClickAway={() => setShowUser(false)}>
                                <div className="--UserDropdown">
                                    <div className="--userInfo">
                                        <div className="--userBox-left">{userAcronym + userLastAcronym}</div>
                                        <div className="--userBox-right">
                                            <label>{userFirstName +" "+ userLastName}</label>
                                            <label>{userNameAdmin}</label>
                                        </div>
                                    </div>
                                    <label className="--menu-row" onClick={LogOut}>Log out</label>
                                    <label className="--menu-version" onClick={()=>{}}>Version {version}</label>
                                </div>
                            </ClickAwayListener>
                        )}
                        <div className={`--formItem`}>
                            {
                                <>
                                    <SearchDropdown className="--search-card" placeholder="Search" value={filterText} onChange={setFilterText} options={options} onclick={SelectedOption} />
                                </>
                            }
                        </div>
                    </> :
                    null
            }
        </div >
    );
};
