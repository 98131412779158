import { FC, useEffect, useState } from "react";
import "./ToggleSwitch.scss";

interface ToggleSwitchInterface {
    active: boolean | undefined;
    setActive: ((val: boolean) => void);
    className?: string;
    style?: React.CSSProperties;
    handleToggle?: ((value: string) => void);
    id?: string
}

export const ToggleSwitch: FC<ToggleSwitchInterface> = ({ active = false, setActive, className = '', style = {}, handleToggle, id }) => {
    const handleToggleClick = (_e: any) => {
        const newValue = !active
        let id = _e.target.id
        if (id === "") id = _e.target.parentElement.id
        setActive(newValue)
        if (handleToggle) {
            handleToggle(id)
        }
    }
    return (
        <div id={id ? id : ""} className={`toggle-container ${active ? "toggle-on" : ""} ${className}`} style={style} onClick={_e => handleToggleClick(_e)}>
            <div className={`toggle-track ${active ? "toggle-on" : ""}`}></div>
            <div className={`toggle-thumb ${active ? "toggle-on" : ""}`}></div>
        </div>
    )
};