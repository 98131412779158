import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Layout } from './Layout'
import { CreateUser } from "./admin/components/CreateUser";
import { UpdateUser } from "./admin/components/UpdateUser";
import { UsersList } from "./admin/components/UsersList";
import { RoleList } from "./admin/components/RoleList";
import { CreateRole } from "./admin/components/CreateRole";
import { ManageRole } from "./admin/components/ManageRole";
import { CreateProject } from "./admin/components/CreateProject";
import { ProjectList } from "./admin/components/ProjectList";
import { AssignProject } from "./admin/components/AssignProject";
// TODO import { CreateLicense } from "./admin/components/CreateLicense";
import { UserSession } from "./admin/components/UserSession";
import { MicroserviceHealth } from "./admin/components/MicroserviceHealth";
import { SSOConfig } from "./admin/components/SSOConfig";
import { EditProject } from "./admin/components/EditProject"
import { CreateLabel } from "./admin/components/CreateLabel";
import { EditLabel } from "./admin/components/EditLabel";
import { ClientHealth } from "./admin/components/ClientHealth";
import { AssignUser } from "./admin/components/AssignUser";
import { NotificationConfig } from "./admin/components/NotificationConfig";
import { EditNotification } from "./admin/components/EditNotification"
import { CreateNotification } from "./admin/components/CreateNotification";
import { SMTPConfig } from './admin/components/SMTPConfig';
import { CreateApiKey } from './admin/components/CreateApiKey';
import { ManageApiKey } from './admin/components/ManageApiKey';
import { EditSSOConfig } from "./admin/components/EditSSOConfig";
import { URLNotFound } from './global/components/UrlNotFound';

const env: boolean = (process.env.NODE_ENV || "").toLowerCase() === "development";

const router = createBrowserRouter([

    {

        path: env ? '/' : 'admin',
        element: <Layout />,
        errorElement: <URLNotFound />,
        children: [
            {
                path: "createuser",
                element: <PrivateRoute><CreateUser /></PrivateRoute>,
            },
            {
                path: "updateuser",
                element: <PrivateRoute>< UpdateUser /></PrivateRoute>
            },
            {
                path: "edituser",
                element: <PrivateRoute>< UsersList /></PrivateRoute>
            },
            {
                path: "editrole",
                element: <PrivateRoute>< RoleList /></PrivateRoute>
            },
            {
                path: "createrole",
                element: <PrivateRoute>< CreateRole /></PrivateRoute>
            },
            {
                path: "managerole",
                element: <PrivateRoute><ManageRole /></PrivateRoute>
            },
            {
                path: "createproject",
                element: <PrivateRoute>< CreateProject /></PrivateRoute>
            },
            {
                path: "projectlist",
                element: <PrivateRoute>< ProjectList /></PrivateRoute>
            },
            {
                path: "assignproject",
                element: <PrivateRoute>< AssignProject /></PrivateRoute>
            },
            {
                path: "usersession",
                element: <PrivateRoute>< UserSession /></PrivateRoute>
            },
            {
                path: "microservicehealth",
                element: <PrivateRoute>< MicroserviceHealth /></PrivateRoute>
            },
            {
                path: "ssoconfig",
                element: <PrivateRoute>< SSOConfig /></PrivateRoute>
            },
            {
                path: "editproject",
                element: <PrivateRoute>< EditProject /></PrivateRoute>
            },
            {
                path: "createlabel",
                element: <PrivateRoute>< CreateLabel /></PrivateRoute>
            },
            {
                path: "editlabel",
                element: <PrivateRoute>< EditLabel /></PrivateRoute>
            },
            {
                path: "clienthealth",
                element: <PrivateRoute><ClientHealth /></PrivateRoute>
            },
            {
                path: "microservice",
                element: <PrivateRoute><MicroserviceHealth /></PrivateRoute>
            },
            {
                path: "assignuser",
                element: <PrivateRoute>< AssignUser /></PrivateRoute>
            },
            {
                path: "notificationconfig",
                element: <PrivateRoute>< NotificationConfig /></PrivateRoute>
            },
            {
                path: "editnotification",
                element: <PrivateRoute>< EditNotification /></PrivateRoute>
            },
            {
                path: "createnotification",
                element: <PrivateRoute>< CreateNotification /></PrivateRoute>
            },
            {
                path: "smtpconfig",
                element: <PrivateRoute><SMTPConfig /></PrivateRoute>
            },
            {
                path: "createapikey",
                element: <PrivateRoute>< CreateApiKey /></PrivateRoute>
            },
            {
                path: "manageapikey",
                element: <PrivateRoute>< ManageApiKey /></PrivateRoute>
            },
            {
                path: "editssoconfig",
                element: <PrivateRoute>< EditSSOConfig /></PrivateRoute>
            },
        ]

    },
    {
        path: "*",
        element: <URLNotFound />
    }
])


function PrivateRoute({ children: children, ...rest }: any) {

    if (!localStorage.getItem('accessToken')) {
        const path = env ? '/' : '/admin/'
        return <Navigate to={path} replace />
    }
    else return children
}

export default router