import { ScreenBlock, Loading } from "../"
import { FC } from "react"
import { useSelector } from "react-redux"
import { RootState } from "../../reducer"
import "./BlockScreenMsg.scss"

//BLOCKS SCREEN WITH LOADING msg
export const BlockScreenMsg: FC = () => {
    const blockMsg = useSelector((state: RootState) => state.globalReducer.blockscreenMsg);
    if (!blockMsg) return null
    return (
        <ScreenBlock>
            <div className="blockscreenmsg-main">
                <div className="load-4">
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>
                <div className="--msg dds-h4-sm">{blockMsg}</div>
            </div>
        </ScreenBlock>
    )
}