import { useRef, useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, PassInfoIconSvg } from "../../global";
import { CALL_NOTIFY } from "../../global/store/action";
import CONST from "../../locale.json"
import { api } from "../../api"
import { RootState } from "../../reducer";
import { SELECTEDADMINSCREEN, SELECTEDLEAF } from "../store/action";
import { ToolTip } from "./ToolTip";
import "./UpdateUser.scss";
const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}
const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    borderRadius: "0px",
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERCOLOR}`,
    },
    fontWeight: 500,
};
const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};
export const UpdateUser = () => {
    const [isChange, setIsChange] = useState(false);
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);
    const [createUser, setCreateUser] = useState("NAME");
    const [fName, setFName] = useState<any>("")
    const [lName, setLName] = useState<any>("")
    const [userName, setUserName] = useState<any>("");
    const [emailID, setEmailID] = useState<any>("");
    const [userType, setUserType] = useState("")
    const [roleType, setRoleType] = useState<any>({});
    const [toolTip, showToolTip] = useState<boolean>(false)
    const [password, setPassword] = useState<string>("")
    const [confirmPassword, setConfirmPassword] = useState<string>("")
    const [newPassword, setNewPassword] = useState<boolean>(false)
    const firstNameRef = useRef<HTMLInputElement>(null)
    const lastNameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const userTypeRef = useRef<HTMLInputElement>(null)
    const userNameRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const confirmPasswordRef = useRef<HTMLInputElement>(null)
    const [passwordCheck, setPasswordCheck] = useState(true);
    // id, label, value to show in dropdown
    const [roleDictionary, setRoleDictionary] = useState<any>([])
    const [user, setUser] = useState<any>([])
    // fetch user role details
    const [role, setRole] = useState<any>("")
    const userDetails = useSelector((state: RootState) => state.adminReducer.selectedUser);
    const devMode = useSelector((state: RootState) => state.globalReducer.setDevMode);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: SELECTEDLEAF, payload: "EDITUSER" });
    }, [])

    useEffect(() => {
        setUpdateButtonDisabled(!(isChange));
    }, [isChange]);

    const updateUser = async () => {
        if (firstNameRef?.current?.value !== "" && !(emailRef?.current?.value === "") && !(userNameRef?.current?.value === "") && (roleType !== "")) {

            let user = {
                userName: userName,
                firstName: fName,
                lastName: lName,
                emailID: emailID,
                type: userType,
                roleID: roleType.id,
                roleName: roleType.value
            }

            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATEUSER, { body: user }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Profile has been successfully updated" } });
            navigate(devMode ? "/edituser" : "/admin/edituser")

        }
        else {
            dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: "Fill required fields!" } });
        }
    }
    const fetchUser = async (username: string, rolename: string) => {
        // fetch user by username
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, { params: `${rolename}/${username}` }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        //set all state
        setUser(resp.data)
        setUserType(resp.data.type);
        setUserName(resp.data.userName);
        setFName(resp.data.firstName);
        setLName(resp.data.lastName);
        setEmailID(resp.data.emailID);
        setPassword("****************");
        setConfirmPassword("****************");
        // fetch role api
        const respRole = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, {}, dispatch)
        if (respRole.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (respRole.err || respRole.msg) } });

        // get role name to set default value in role 
        for (var itr of respRole.data) {
            if (itr.id === resp.data.roleID) {
                setRole({ id: itr.id, value: itr.name, label: itr.name })
                setRoleType({ id: itr.id, value: itr.name, label: itr.name })
            }
        }


        // store them as id, label, value format to show in dropdown
        var roleDictionary: any = []
        for (var itr of respRole.data) {
            // if it is not admin role then hide the admin role : restriction of user and admin role updation
            if (role.value != "admin" && itr.name != "admin")
                roleDictionary.push({ id: itr.id, value: itr.name, label: itr.name })
        }
        setRoleDictionary(roleDictionary)

    }

    const setName = useCallback((i: EventTarget & HTMLInputElement, name: string) => {
        setIsChange(true);
        switch (name) {
            case "firstName":
                setFName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
                break;
            case "lastName":
                setLName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
                break;
            case "userName":
                setUserName(i.value.replace(/[^a-zA-Z0-9_]/g, ""));
                break;
            case "email":
                setEmailID(i.value.replace(/[^a-zA-Z0-9_@.]/g, ""));
                break;
            default:
                break;
        }
    }, []);
    const passwordValidation = (e: any) => {
        var regex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$");
        setPasswordCheck(regex.test(e.target.value));
        setPassword(e.currentTarget.value);
        setNewPassword(true)
    }
    const ChangeRole = (e: any) => {
        setRoleType({ id: e.id, label: e.name, value: e.value })
        setIsChange(true)
    }
    useEffect(() => {
        // fetch user details by username
        fetchUser(userDetails.userName, userDetails.roleName)
    }, [])
    const handleRoleChange = (selectedRole: any) => {
        setRoleType({ id: selectedRole.id, value: selectedRole.name });
    };

    return (
        <div className="update-user-container">
            {(() => {
                return (<>
                    <h3 style={{ marginBottom: "80px", marginLeft: "12%" }}>Edit user</h3>
                    <div className="update-user-div dds-link">
                        <label>Username</label>
                        <div className="update-user-txtbox">
                            <input className="admin-textbox disable" readOnly={true} value={userName} ref={userNameRef} type="text" />
                        </div>
                    </div>
                    <div className="update-user-div dds-link">
                        <label>First name</label>
                        <div className="update-user-txtbox">
                            <input className="admin-textbox" value={fName} ref={firstNameRef} onChange={(e) => setName(e.target, "firstName")} type="text" />
                        </div>
                    </div>
                    <div className="update-user-div dds-link">
                        <label>Last name</label>
                        <div className="update-user-txtbox">
                            <input className="admin-textbox" value={lName} ref={lastNameRef} onChange={(e) => setName(e.target, "lastName")} type="text" />
                        </div>
                    </div>
                    <div className="update-user-div dds-link">
                        <label>Email ID</label>
                        <div className="update-user-txtbox">
                            <input className="admin-textbox" value={emailID} ref={emailRef} style={{ opacity: "0.6" }} readOnly={true} onChange={(e) => setName(e.target, "email")} type="text" />
                        </div>
                    </div>
                    <div className="update-user-div dds-link">
                        <label>Role type</label>
                        <div className="update-user-txtbox">
                            <Dropdown placeholderStyle={placeholderStyle} style={{ width: "470px" }} controlStyle={DropdownStyle} dropdownIndicatorStyle={DropdownIndicatorStyle} showValue defaultValue={role} onchange={ChangeRole} options={roleDictionary} placeholder="" disabled={role.value == "admin" ? true : false} />
                        </div>
                    </div>
                </>
                )
            }
            )()
            }
            <div className="update-user-btn">
                <Button title={"Cancel"} style={{ backgroundColor: 'white', color: 'green' }} className="--savebtn" onClick={() => navigate(devMode ? "/edituser" : "/admin/edituser")} disabled={false} />
                <Button title={"Update"} className="--savebtn" onClick={updateUser} disabled={updateButtonDisabled} />
            </div>
        </div >
    )
}