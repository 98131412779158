import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button, Dropdown, AdminSAPIcon, AdminWebIconWhite, CloseWhiteIcoSvg, AdminAPIIcon, AdminDesktopIcon, AdminWebIcon, AdminDatabaseIcon,AdminSAPIconWhite , AdminAPIIconWhite , AdminDatabaseIconWhite , AdminDesktopIconWhite } from "../../global";
import CONST from "../../locale.json";
import { api } from "../../api";
import { CALL_NOTIFY } from "../../global/store/action";
import { SELECTEDPARENT, SELECTEDLEAF } from "../store/action";
import { TableView } from './TableView';
import "./AssignProject.scss";

const DropdownStyle = {
    border: `1px solid ${CONST.COLOR.BORDERBLACK}`,
    boxShadow: "none",
    ":hover": {
        border: `1px solid ${CONST.COLOR.BORDERBLACK}`,
    },
    ":active": {
        border: `1px solid ${CONST.COLOR.BORDERBLACK}`,
    },
    fontWeight: 500,
};

const DropdownIndicatorStyle = {
    color: CONST.COLOR.HEADERCOLOR,
    ":hover": {
        color: CONST.COLOR.HEADERCOLOR,
    }
}

const placeholderStyle = {
    color: CONST.COLOR.BORDERBLACK,
};

const header = [{
    "id": "id",
    "firstName": "User profile",
    "userName": "Username",
    "projects": "Projects"
}];
export const AssignProject = () => {

    // list to store already assigned projects
    const [assignedList, setAssignedList] = useState<any>([]);
    // list to store new projects
    const [list, setList] = useState<any>([]);
    const [selectedApp, setSelectedApp] = useState("");
    const [selectedUser, setSelectedUser] = useState<any>({});
    const [userList, setUserList] = useState([]);
    const userNameRef = useRef<HTMLInputElement>(null)
    // all projects
    const [projectList, setProjectList] = useState<any>([])
    const [projects, setProjects] = useState<any>([])
    // user projects boolean map 
    const [selectedProjects, setSelectedProjects] = useState<any>([])
    // first project assignment flag  
    const [flag, setFlag] = useState<boolean>(false);
    const [showProjectTable, setShowProjectTable] = useState<boolean>(true);
    const dispatch = useDispatch();
    const [updateButtonDisabled, setUpdateButtonDisabled] = useState(true);

    useEffect(() => {
        dispatch({ type: SELECTEDPARENT, payload: 'User and Role Management' })
        dispatch({ type: SELECTEDLEAF, payload: 'ASSIGNPROJECT' })
    }, [])

    const addToList = (e: any) => {

        // if selected project already assigned and present in assigned list and selected projects map then do not add to list 
        if (selectedProjects[e.id]) {
            return
        }

        // update the boolean map for selected projecct
        selectedProjects[e.id] = true

        for(var itr of projects){
            if(e.id===itr.id){
                e.appType = itr.appType
                break
            }
        }
        // add the selected project to assigned list
        setList((prev: any) => [...prev, { id: e.id, label: e.value.toString(), value: e.value.toString(), appType: e.appType.toString() }])

        setUpdateButtonDisabled(false);
    }

    const removeFromList = (e: any) => {
        // update the boolean map for removed project
        selectedProjects[e.id] = false
        // remove the project from assigned list
        setList((state: any) => state.filter((item: any) => item !== e))
        setUpdateButtonDisabled(false);
    }

    const assignProject = async () => {

        // assigned list contain id, label, value ; push only ids into userProject  
        var userProjects: any = []
        for (var itr of assignedList) {
            userProjects.push(itr.id)
        }
        for (var itr of list) {
            userProjects.push(itr.id)
        }
        // if this is first project assignment of user : user does not already exist in user project table
        if (flag == true) {
            let userProject = {
                userID: selectedUser.id,
                projectIDs: userProjects
            }
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.ASSIGNPROJECT, { params: "user", body: userProject }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            setShowProjectTable(true);
            setList([])
            setFlag(false)
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully updated" } });
            setUpdateButtonDisabled(true);
        }
        // if user is present in table
        else {
            let userProject = {
                projectIDs: userProjects
            }
            const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.UPDATEASSIGNPROJECT, { params: `user/${selectedUser.id}`, body: userProject }, dispatch)
            if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
            setShowProjectTable(true);
            setList([])
            dispatch({ type: CALL_NOTIFY, payload: { type: "SUCCESS", msg: "Project has been successfully updated" } });
        }

        // whenever an operation will be done on a user then user will be logged out
        // var res = await api(CONST.CHANNEL.AUTH, CONST.METHOD.AUTH.LOGOUT, { body: { userName: selectedUser.name }, params: "/logout/user" });
        // if (res.err) {
        //     dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: res.msg } });
        // }

        await fetchUsers()
        await fetchProjects("all")
    }

    const fetchAssignProject = async (userId: string) => {

        // fetch projects for userId
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHASSIGNPROJECT, { query: { userID: userId } }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        // if user is not present in user project table
        if (typeof resp.data === "undefined") {
            setSelectedProjects([])
            setAssignedList([])
            setList([])
            setFlag(true)
        }
        // if user is present in user project table
        else {

            // make a boolean map and set true to already assigned projects to the user
            var selectedProjects: any = []
            for (var itr of resp.data[userId]) {
                selectedProjects[itr.projectID] = true
            }
            // update in state variable
            setSelectedProjects(selectedProjects)

            // get id, label, value of already assigned projects in list : assignedProjectList
            var assignedProjectList: any = []
            for (var itr of projects) {
                if (selectedProjects[itr.id]) {
                    assignedProjectList.push({ id: itr.id, label: itr.name, value: itr.name, appType: itr.appType })
                }
            }

            // set state variable
            setFlag(false)
            setList(assignedProjectList);
        }
    }

    const fetchUsers = async () => {

        // fetch user api
        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSER, {}, dispatch);
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;
        const respUserProject = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHUSERPROJECT, { params: "user" }, dispatch)

        // filter non admin users
        const respRole = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHROLE, {}, dispatch)
        if (respRole.err) return dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (respRole.err || respRole.msg) } });

        let adminRoleId: string = ''
        for (var itr of respRole.data) {
            if (itr.name === "admin") {
                adminRoleId = itr.id
            }
        }

        resp.data = resp.data.data.filter((user: any) => user.roleID !== adminRoleId)

        let userProjectMap: Record<string, boolean> = {}
        for (var itr of respUserProject.data) {
            userProjectMap[itr.user_userID] = itr.projects
        }

        for (var itr of resp.data) {
            if (itr.roleID != adminRoleId) {
                if (userProjectMap[itr.id]) {
                    itr.projects = userProjectMap[itr.id]
                }
                else {
                    itr.projects = 0
                }
            }
        }

        resp.data.forEach((item: any) => {
            for (let key in item) {
                if (!(Object.keys(header[0]).includes(key))) {
                    if (key === "lastName") item["firstName"] = item["firstName"] + " " + item["lastName"]
                    delete item[key]
                }
            }
        })
        setUserList(resp.data)
    }

    const fetchProjects = async (params: string, disabled: boolean = false) => {
        if (disabled) return
        setSelectedApp(params)

        // if fetch all projects
        if (params == "all")
            params = 'all'
        // fetch sap/web projects
        else {
            params = `all/${params}`
        }

        const resp = await api(CONST.CHANNEL.ADMIN, CONST.METHOD.ADMIN.FETCHPROJECT, { params: params }, dispatch)
        if (resp.err) return resp.err !== "401" ? dispatch({ type: CALL_NOTIFY, payload: { type: "ERROR", msg: (resp.err || resp.msg) } }) : null;

        // set all projects/ web / sap
        setProjects(resp.data.data)

        // get id, label, value for showing of project
        var prjList: any = []
        for (var itr of resp.data.data) {
            prjList.push({ id: itr.id, label: itr.name, value: itr.name })
        }
        // update state variable
        setProjectList(prjList)

    }
    const getTargetIcon = useCallback((app: string) => ({
        web: AdminWebIcon,
        sap: AdminSAPIcon,
        api: AdminAPIIcon,
        database: AdminDatabaseIcon,
        desktop: AdminDesktopIcon
    }[app]), []);

    const getTargetIconInList = useCallback((appType: string) => ({
        web: AdminWebIconWhite,
        sap: AdminSAPIconWhite,
        api: AdminAPIIconWhite,
        database: AdminDatabaseIconWhite,
        desktop: AdminDesktopIconWhite,
    }[appType.toLowerCase()]), []);

    const editAssignProject = (id: string, name: string) => {
        setShowProjectTable(false);
        setSelectedUser({ "id": id, "name": name })
        fetchAssignProject(id)
    }

    useEffect(() => {
        //Fetch user list
        fetchUsers();
        // fetch all projects
        fetchProjects('all')
        setUpdateButtonDisabled(true);
    }, [])

    return (
        <>
            {
                showProjectTable ? <> <TableView Title={"Assign/unassign project"} tableData={userList} showSLNo={true} header={Object.values(header[0])} filter="userName" onClickEdit={(e) => editAssignProject(e.id, e.userName)} /></> :
                    <div className="assign-project-container">
                        <h3 className="dds-h5-lm">Assign/unassign projects</h3>
                        <div className="assign-project dds-link">
                            <label>Username</label>
                            <div className="assign-prject-content">
                                <input className="admin-assignprj-textbox disabled" ref={userNameRef} value={selectedUser.name} type="text" readOnly />
                            </div>
                        </div>
                        <div className="select-app-div" style={{ marginBottom: '2px' }}>
                            <span>Assign new project</span>
                        </div>
                        <div className="select-app-div">
                            <label>App type</label>
                        </div>
                        <div className="select-app">
                            {CONST.appType.map((e) => (
                                <div key={e.name} className={`${(e.name === selectedApp) ? "selectedApp apptype-select" : "apptype-select"} ${e.disabled ? "disable-feature" : ""}`} onClick={() => { fetchProjects(e.name, e.disabled) }} >
                                    <img src={getTargetIcon(e.icon)}></img>
                                    <label>{e.name}</label>
                                </div>))}
                        </div >
                        <div className="assign-project">
                            <label>Add projects</label>
                            <div className="assign-prject-content">
                                <Dropdown className="--headerproj"
                                    dropdownIndicatorStyle={DropdownIndicatorStyle}
                                    controlStyle={DropdownStyle} placeholderStyle={placeholderStyle}
                                    showValue={false} onchange={addToList}
                                    placeholder={"Search projects"}
                                    isSearchable options={projectList} />
                                <div className="assign-new-prj-list">{list.map((element: any) =>
                                    <div className="Prj-Label dds-link-sm">
                                        <img src={getTargetIconInList(element.appType)}></img>
                                        <span title={element.value.toString()}>
                                            {element.value.toString()}
                                        </span>
                                        <img style={{ width: "10%" }} src={CloseWhiteIcoSvg} onClick={() => removeFromList(element)}>
                                        </img>
                                    </div>
                                )}</div>
                            </div>
                        </div>

                        <div className="create-user-btn">
                            <Button title="Back" className="--savebtn" style={{ backgroundColor: 'white', color: 'green' }} onClick={() => setShowProjectTable(true)} disabled={false} />
                            <Button title="Update" className="--savebtn" onClick={assignProject} disabled={updateButtonDisabled} />
                        </div>
                    </div>
            }</>
    )
}