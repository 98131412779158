import { FC, useState, useEffect } from "react";
import { Grid, GridItem, SearchBox } from "../../global";
import CONST from "../../locale.json";
import './TableForApi.scss';

interface TableForApiInterface {
    Title: string,
    onClickEdit?: (e1: string, e2: string) => void,
    tableData: any,
    filter: string,
}

type headerItems = "keyName" | "shortApiKey" | "createdOn" | "expiry" | "status";
const header: Record<headerItems, string> =
{

    "keyName": "Key name",
    "shortApiKey": "API key",
    "createdOn": "Created on",
    "expiry": "Expiry",
    "status": "Status"
};
const menu: any = CONST.menu;
const headerOrder: headerItems[] = ["keyName", "shortApiKey", "createdOn", "expiry", "status"];
export const TableForApi: FC<TableForApiInterface> = ({ Title, onClickEdit, tableData, filter }) => {
    const [data, setData] = useState<any>();
    const [options, setOptions] = useState<any>(menu);
    const [filterText, setFilterText] = useState<string>("");


    useEffect(() => {
        setData(
            tableData?.filter((p: Record<string, any>) => p[filter]?.toLowerCase().includes(filterText?.toLowerCase()))
        )
    }, [tableData, tableData, filterText])

    const onEdit = (status: string, id: string) => {
        if (onClickEdit) {
            onClickEdit(status, id);
        }
    }

    return (
        <div className="table-for-api-container">
            <div className="table-for-api-heading">
                <h3 className={"dds-h5-lm"}>{Title}</h3>
                <div className="search">
                    <SearchBox className="--search-card" value={filterText} onChange={setFilterText} placeholder={"Search"} />
                </div>
            </div>
            <div className="api-table-body">
                <Grid className="table-for-api-header dds-label-semibold" columns={7} style={{ gap: "0px" }}>
                    {headerOrder.map((e) => (
                        <GridItem xs={header[e] === "Created on" || header[e] === "Expiry" ? 1.55 : 1.30} className="table-for-api-content"  >{header[e]}</GridItem>)
                    )}
                </Grid>
                <div className="api-main-data dds-link">
                    {data?.map((e: any, index: number) => (
                        <Grid key={index} className="table-for-api-content dds-link" columns={7} style={{ gap: "0px" }}>
                            {
                                headerOrder.map((val: headerItems) => (
                                    val === "shortApiKey" ? <GridItem xs={1.3} className={"api-table-item"} title={e[val]}>{e[val]}*****</GridItem> :
                                    (val==="keyName")? <GridItem xs={1.3} className={"api-table-item"} title={e[val]}>
                                    {e[val]}</GridItem>:
                                        val !== "status" ?
                                            <GridItem xs={val === "createdOn" || val === "expiry" ? 1.55 : 1} className={"api-table-item"}>{e[val]}</GridItem> :
                                            e[val] == "active" ? <GridItem xs={1.3} className={"api-table-item --notdone"} onClick={() => onEdit(e.status, e.id)}>Revoke</GridItem> :
                                                e[val] == "revoked" ? <GridItem xs={1.3} className={"api-table-item disable-edit --done"} >Revoked</GridItem> :
                                                    <GridItem xs={1.3} className={"api-table-item disable-edit --done"}>Expired</GridItem>

                                )
                                )
                            }
                        </Grid>
                    )
                    )}
                </div>
            </div>
        </div >

    )

}